import axios from "axios";
import { Toast } from "vant";
import router from "@/router"; // 确保你引入了路由
// 获取基础 URL
const baseUrl = process.env.VUE_APP_BASE_API;
// 创建 axios 实例
const axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 10000, // 请求超时时间
});
// 请求拦截器
axiosInstance.interceptors.request.use((config) => {
    const headerData = {
        "content-type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token") || "",
    };
    config.headers = headerData;
    // 确保 OPTIONS 请求也被正确发送（有时候可以避免跨域问题）
    if (config.method?.toUpperCase() === "OPTIONS") {
        config.headers["Access-Control-Allow-Origin"] = "*";
        config.headers["Access-Control-Allow-Methods"] =
            "GET, POST, PUT, DELETE, OPTIONS";
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
// 响应拦截器
axiosInstance.interceptors.response.use((response) => {
    // 检查返回的数据结构中的 code 值
    if (response.data.code === 2000) {
        Toast.fail("Token已过期，请重新登录");
        // 清除本地的用户信息和 token
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        // 跳转到登录页面
        setTimeout(() => {
            router.push("/login");
        }, 2000);
        return Promise.reject(new Error("Token已过期"));
    }
    // 返回正常响应数据
    return response.data;
}, (error) => {
    // 处理错误响应
    if (error.response) {
        const { status, data } = error.response;
        // 根据状态码显示不同的错误信息
        if (status === 401) {
            Toast.fail("未授权，请登录");
            // 清除本地的用户信息和 token
            localStorage.removeItem("token");
            localStorage.removeItem("userInfo");
            // 跳转到登录页面
            setTimeout(() => {
                router.push("/login");
            }, 2000);
        }
        else if (status === 403) {
            Toast.fail("禁止访问");
        }
        else if (status === 500) {
            Toast.fail("服务器错误");
        }
        else {
            Toast.fail(data.message || "请求出错");
        }
    }
    else {
        // 处理无响应的情况
        Toast.fail("网络错误，请稍后重试");
    }
    return Promise.reject(error);
});
// 封装 GET 请求
export function get(url, params) {
    return axiosInstance.get(url, { params });
}
// 封装 POST 请求
export function post(url, data) {
    return axiosInstance.post(url, data);
}
